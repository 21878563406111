
var myaudio = document.getElementById("audioID");
myaudio.volume = 0.4

var musicStopped = false;
var currentlyPlaying = true;
var play_icon =  document.getElementById("play_icon");
var pause_icon =  document.getElementById("pause_icon");


play_icon.onclick = function (){
	musicStopped = false;
	currentlyPlaying = true;
	myaudio.play();
	pause_icon.style.display = "block";
	play_icon.style.display = "none";
}

pause_icon.onclick = function (){
	musicStopped = true;
	currentlyPlaying = false;
	myaudio.pause();
	play_icon.style.display = "block";
	pause_icon.style.display = "none";
}

function hasFocus(){
	return document.hasFocus()
}


function checkFocus(){
	
  if(hasFocus() == false && currentlyPlaying == true){
    myaudio.pause();
		play_icon.style.display = "block";
		pause_icon.style.display = "none";
		currentlyPlaying = false
   }
	if(hasFocus() == true && musicStopped == false && currentlyPlaying == false){
			currentlyPlaying = true;
			myaudio.play();
			pause_icon.style.display = "block";
			play_icon.style.display = "none";
	}
}

var promise = document.getElementById('audioID').play();

if (promise !== undefined) {
  promise.then(_ => {
    // Autoplay started!
    console.log("Autoplay started")
    play_icon.click();
    //setInterval( checkFocus, 1000 );
  }).catch(error => {
  		console.log("Autoplay blocked");
  		pause_icon.click();
  		//setInterval( checkFocus, 1000 );
    // Autoplay was prevented.
    // Show a "Play" button so that user can start playback.
  });
}




